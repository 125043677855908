import { gql } from '@apollo/client';

export const GET_COMPANY_ARTEFACT_TYPES = gql`
  query CompanyArtefactTypes($companyId: Int) {
    company_artefact_types(company_id: $companyId) {
      id
      pk
      created_at
      updated_at
      company {
        id
        name
        company_type
      }
      name
      type
      icon_image
      learning_object_type {
        id
        name
        type
      }
      is_final
      order
      #      cob_artefact_type
    }
    company_separate_task_types(company_id: $companyId) {
      id
      name
    }
  }
`;
