import React, { useCallback, useMemo } from 'react';

import { AppstoreOutlined, BorderOutlined, DeleteOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { cx } from 'utils';
import { InputNumber } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { TemplateCommonProps } from 'common/commonPropsProvider';
import { BusinessRoleEnum } from 'types/entities/user';
import withCommonProps from 'hocs/withCommonProps';

import Button from 'components/button';
import Block from 'components/block';
import { ButtonTypesEnum } from 'components/button/types';

import useProjectTask from 'hooks/queries/useProjectTask';
import useCommonContext from 'hooks/useCommonContext';

import { CarouselControlProps, CarouselDisplayEnum } from './types';

function CarouselControl({
  className,
  userRole,
  controlState,
  dataLength,
  onChangeCurrent,
  onClickDisplay,
  onClickPrev,
  onClickNext,
  onDeleteAllSlides,
}: CarouselControlProps & TemplateCommonProps) {
  const [searchParams] = useSearchParams();
  const common = useCommonContext();
  const version = searchParams.get('version');

  const classNames = cx('carousel-control', className);
  const classNamesBtnSlide = cx('carousel-control__btn-slide', {
    'carousel-control__btn--active': controlState.display === CarouselDisplayEnum.single,
  });
  const classNamesBtnGrid = cx('carousel-control__btn-grid', {
    'carousel-control__btn--active': controlState.display === CarouselDisplayEnum.grid,
  });

  const { projectTask } = useProjectTask(version, 'cache-only');

  const isCurrentExecutor = useMemo(() => {
    return projectTask?.project_task?.projecttaskassignee_set?.find(
      (a) => Number(a?.project_user?.company_user?.user?.id) === common?.userId
    )?.is_current_executor;
  }, [common.userId, projectTask?.project_task?.projecttaskassignee_set]);

  const onClickSingle = useCallback(() => {
    onClickDisplay(CarouselDisplayEnum.single);
  }, [onClickDisplay]);

  const onClickGrid = useCallback(() => {
    onClickDisplay(CarouselDisplayEnum.grid);
  }, [onClickDisplay]);

  const showDeleteBlock =
    isCurrentExecutor && (userRole === BusinessRoleEnum.author || userRole === BusinessRoleEnum.productionExecutor);

  return (
    <div className={classNames}>
      <Block hidden={controlState.display === CarouselDisplayEnum.grid} className="carousel-control__left">
        <span className="carousel-control__slide-text">{common.t<string>('common.slide')}</span>
        <Button
          className="carousel-control__btn-left"
          type={ButtonTypesEnum.link}
          icon={<LeftOutlined />}
          disabled={controlState.current === 1}
          onClick={onClickPrev}
        />
        <InputNumber
          controls={false}
          min={1}
          size="small"
          className="carousel-control__input mr_8"
          value={controlState.current}
          onChange={onChangeCurrent}
        />
        <span className="carousel-control__count">{`${common.t<string>('common.of')} ${dataLength}`}</span>
        <Button
          className="carousel-control__btn-right"
          type={ButtonTypesEnum.link}
          icon={<RightOutlined />}
          disabled={controlState.current === dataLength}
          onClick={onClickNext}
        />
      </Block>
      <div className="carousel-control__right">
        <Block empty hidden={!showDeleteBlock}>
          <Button className="mr_24" type={ButtonTypesEnum.default} onClick={onDeleteAllSlides}>
            <Block empty>
              <DeleteOutlined />
              <span className="ml_8">{common.t<string>('common.deleteAllSlides')}</span>
            </Block>
          </Button>
        </Block>
        <Button
          className={classNamesBtnSlide}
          type={ButtonTypesEnum.link}
          icon={<BorderOutlined />}
          onClick={onClickSingle}
        />
        <Button
          className={classNamesBtnGrid}
          type={ButtonTypesEnum.link}
          icon={<AppstoreOutlined />}
          onClick={onClickGrid}
        />
      </div>
    </div>
  );
}

export default withCommonProps(CarouselControl);
